<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="70%" @click:outside="clickOutside">
      <template v-slot:activator="{ on, attrs }">
        <p id="fpbFormEdit" v-bind="attrs" v-on="on" @click="update"></p>
      </template>
      <div v-if="detail !== null">
        <v-container
          style="max-width:100%;background: white; border-radius: 5px"
        >
          <v-form
            readonly
            @submit.prevent="submit"
            ref="entryForm"
            style="position: relative"
          >
            <v-card
              flat
              style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline"
                  ><p style="font-size: 20px; margin: 0; font-weight: bold">
                    Edit FPB
                  </p>
                </v-toolbar-title>
              </div>
              <div>
                <!-- <v-btn
                  type="submit"
                  tile
                  color="black"
                  small
                  class="ma-2"
                  style="color: white"
                  :loading="loading"
                  :disabled="detail.status !== 0"
                >
                  simpan
                </v-btn> -->

                <v-btn
                  small
                  tile
                  color="black"
                  class="ma-2"
                  style="color: white"
                  @click="close"
                  :loading="loading"
                >
                  tutup
                </v-btn>
              </div>
            </v-card>

            <v-row style="padding: 15px">
              <!-- <v-col
                md="5"
                style="padding: 0;display:flex;justify-content:center;align-items:center;"
              >
                <div style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <p
                      style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Dokumen dapat kadaluarsa ?
                    </p>

                    <v-checkbox
                      style=" margin:0 0 0 10px; height:25px; padding:0;"
                    ></v-checkbox>
                  </v-col>
                </div>
              </v-col> -->

              <v-col
                md="5"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
              >
                <div style="padding: 0 10px;width:100%;">
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <p
                      style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Has Budget ?
                    </p>

                    <v-checkbox
                      v-model="form.has_budget"
                      style=" margin:0 0 0 10px; height:25px; padding:0;"
                    ></v-checkbox>
                  </v-col>
                </div>
              </v-col>
              <v-col md="2" style="padding: 0"></v-col>
              <v-col md="5" style="padding: 0; ">
                <div
                  v-if="param.act === 'add'"
                  style="padding: 0 10px;margin-bottom:20px;"
                >
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Catalogue Item
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="selected"
                      :items="catalogueItems"
                      :filter="customFilter"
                      clearable
                      outlined
                      item-text="product.name"
                      item-value="id"
                      hide-details
                      hide-no-data
                      return-object
                      label=""
                      :loading="loading"
                      :search-input.sync="search"
                      style="margin: 0"
                      dense
                      @keydown.enter.prevent="enterSearch(search)"
                      ><template v-slot:item="{ item }">
                        <div style="width:830px;">
                          <v-expansion-panels accordion>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <div
                                  style="display:flex; justify-content:flex-start; align-items:center;"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-toolbar-title
                                        v-bind="attrs"
                                        v-on="on"
                                        style="text-align:left; font-size:14px; padding:0;width: 200px;"
                                      >
                                        {{ item.product.name }}
                                      </v-toolbar-title>
                                    </template>
                                    <span>{{ item.product.name }}</span>
                                  </v-tooltip>
                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>
                                  <v-toolbar-title
                                    style="text-align:left; font-size:14px; padding:0;width: 120px;"
                                  >
                                    #{{ item.product.code }}
                                  </v-toolbar-title>

                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-toolbar-title
                                        v-bind="attrs"
                                        v-on="on"
                                        style="text-align:left; font-size:14px; padding:0;width: 200px;"
                                      >
                                        {{ item.vendor.name }}
                                      </v-toolbar-title>
                                    </template>
                                    <span>{{ item.vendor.name }}</span>
                                  </v-tooltip>

                                  <v-divider
                                    class="mx-3"
                                    inset
                                    vertical
                                  ></v-divider>

                                  <v-toolbar-title
                                    style="text-align:left; font-size:14px; padding:0;width: 120px;"
                                  >
                                    #{{ item.vendor.code }}
                                  </v-toolbar-title>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                Put more data here
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                      </template>
                      <template v-slot:append-item>
                        <v-list-item>
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>
                              mdi-cloud-search
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <a href="#">
                              Search more!
                            </a>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </div>
              </v-col>

              <!-- ---------------------IF act === UPDATE-------------------- -->
              <v-col v-if="param.act === 'update'" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Item Name
                    </p>
                  </v-col>
                  <v-col
                    v-if="form.product !== undefined"
                    cols="12"
                    style="padding: 0;"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :value="form.product.name"
                          dense
                          outlined
                        />
                      </template>
                      <span>{{ form.product.name }}</span>
                    </v-tooltip>
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="param.act === 'update'"
                md="2"
                style="padding: 0"
              ></v-col>
              <v-col v-if="param.act === 'update'" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Item Code
                    </p>
                  </v-col>
                  <v-col
                    v-if="form.product !== undefined"
                    cols="12"
                    style="padding: 0;"
                  >
                    <v-text-field
                      readonly
                      :value="form.product.code"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col v-if="param.act === 'update'" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Supplier Name
                    </p>
                  </v-col>
                  <v-col
                    v-if="form.vendor !== undefined"
                    cols="12"
                    style="padding: 0;"
                  >
                    <v-text-field
                      readonly
                      :value="form.vendor.name"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                v-if="param.act === 'update'"
                md="2"
                style="padding: 0"
              ></v-col>
              <v-col v-if="param.act === 'update'" md="5" style="padding: 0">
                <div style="padding: 0 10px;">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Supplier Code
                    </p>
                  </v-col>
                  <v-col
                    v-if="form.vendor !== undefined"
                    cols="12"
                    style="padding: 0;"
                  >
                    <v-text-field
                      readonly
                      :value="form.vendor.code"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <!-- ---------------------------------------------------------------- -->

              <v-col md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Budget / Item
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.budget_price_per_unit"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col md="2" style="padding: 0"></v-col>
              <v-col md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Harga Penawaran
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.quotation_price_per_unit"
                      dense
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Qty
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.qty"
                      type="number"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col md="2" style="padding: 0"></v-col>
              <v-col md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tgl Diperlukan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0"
                    ><v-text-field
                      v-model="form.estimate_date_use"
                      outlined
                      dense
                      type="date"
                      name="date"
                      step="1"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0"
                    ><p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Upload File
                      <span style="font-size:9px; color:orange;">
                        <b>(cooming soon)</b>
                      </span>
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-file-input
                      disabled
                      prepend-icon=""
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                </div>
              </v-col>

              <v-col md="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Alasan Permintaan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-textarea
                      v-model="form.description"
                      dense
                      auto-grow
                      outlined
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  props: ['param', 'detail'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      dialog: false,

      selected: null,
      catalogueItems: [],
      search: null,
      select: null,
      loading: false,
      form: {},
      itemNameRules: []
    }
  },
  watch: {
    search() {
      if (this.search === null || this.search === '') {
        this.catalogueItems = []
      }
    },
    selected() {
      console.log(this.selected)
    }
  },
  computed: {
    ...mapGetters([
      'getDropdownProduct',
      'getDropdownVendor'
      // "getDropdownDocStatus",
    ])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    enterSearch(val) {
      val && val !== this.select && this.querySelections(val)
    },
    clear() {
      // this.docTypeRules = [];
      // this.docNameRules = [];
      // this.docNoRules = [];
      // this.docDateRules = [];
      // this.locationRules = [];
      // this.picRules = [];
      // this.publishedByRules = [];
      // this.publishedCityRules = [];
      // this.statusRules = [];
      // this.form = {};
      // this.$emit("loadPage");
    },
    close() {
      this.dialog = false
    },
    clickOutside() {
      this.dialog = false
    },
    add() {
      this.form = {
        request_id: this.detail.id,
        product: null,
        qty: 0,
        budget_price_per_unit: 0,
        quotation_price_per_unit: 0,
        vendor: null,
        has_budget: 0,
        estimate_date_use: '',
        description: ''
      }
    },
    update() {
      console.log(this.param)
      this.form = {
        request_id: this.detail.id,
        product: this.param.items.product,
        qty: this.param.items.qty,
        budget_price_per_unit: this.param.items.budget_price_per_unit,
        quotation_price_per_unit:
          this.param.items.quotation_price_per_unit !== undefined
            ? this.param.items.quotation_price_per_unit
            : 0,
        vendor: this.param.items.vendor,
        has_budget: this.param.items.has_budget,
        estimate_date_use: this.param.items.estimate_date_use,
        description: this.param.items.description
      }
    },
    submit() {
      this.loading = true
      // this.docTypeRules = [v => !!v || 'Document Type is required']
      // this.docNameRules = [v => !!v || 'Document Name is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm(self.form)
        }
      })
    },
    createNewForm(form) {
      console.log(form)
      // const type = this.form.document_type_id
      const newForm = {
        request_id: form.request_id,
        qty: form.qty,
        budget_price_per_unit: form.budget_price_per_unit,
        quotation_price_per_unit: Number(form.quotation_price_per_unit),
        has_budget: form.has_budget ? 1 : 0,
        estimate_date_use: form.estimate_date_use,
        description: form.description
      }

      if (this.param.act === 'update') {
        Object.assign(newForm, {
          act: this.param.act,
          id: this.param.items.id,
          product_id: Number(form.product.id),
          product_name: form.product.name,
          product_code: form.product.code,
          vendor_id: Number(form.vendor.id),
          vendor_name: form.vendor.name,
          vendor_code: form.vendor.code
        })
      } else {
        Object.assign(newForm, {
          act: this.param.act,
          product_id: Number(this.selected.product.id),
          product_name: this.selected.product.name,
          product_code: this.selected.product.code,
          vendor_id: Number(this.selected.vendor.clear_id),
          vendor_name: this.selected.vendor.name,
          vendor_code: this.selected.vendor.code
        })
      }
      this.save(newForm)
    },
    save(form) {
      console.log(form)
      axios
        .post(`${this.proc}fpb/save_detail`, form)
        .then(res => {
          console.log(res)

          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            if (this.param.act === 'add') {
              this.close()
              this.$emit('loadPage')
            } else {
              this.$emit('loadPage')
            }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    querySelections(v) {
      const getCatalogue = () => {
        return new Promise(resolve => {
          axios
            .get(
              `${this.proc}vendor/catalogue/list?keyword=${v}&offset=0&limit=5&vendor_id=&category_id=`
            )
            .then(res => {
              resolve(res.data.data)
            })
        })
      }
      this.loading = true
      getCatalogue().then(res => {
        console.log(res)
        this.catalogueItems = res
        this.loading = false
      })
    },

    customFilter(item, queryText, itemText) {
      console.log(item.product, item.vendor)
      return (
        item.product.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.product.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.merk.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      )
    }
  }
}
</script>
<style lang="scss" scoped></style>
